import styled from "styled-components";

const ButtonStyles = styled.button`
  background: var(--accent-dark);
  color: var(--white);
  text-decoration: none;
  padding: 15px 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  border: none;
  font-family: "Open Sans";
  cursor: pointer;
  margin-top: 15px;
  text-align: center;

  font-size: 1.6rem;

  @media(min-width: 1200px) {
    font-size: 2rem;
  }

  @media(min-width: 1200px) {
    font-size: 2rem;
  }

  @media(min-width: 1400px) {
    font-size: 2.2rem;
  }

  a {
    color: var(--white);
    font-size: 1em;
  }

  &:hover,
  &:focus,
  &:active {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    opacity: 1;
  }
`;

export default ButtonStyles;
