import React from 'react';
import ButtonStyles from '../styles/ButtonStyles';
import useForm from '../utils/useForm';
import useContact from '../utils/useContact';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Form = () => {
  const { values, updateValues, resetValues } = useForm({
    naam: '',
    email: '',
    bericht: '',
    siroop: '',
  });

  const { submitContact, loading } = useContact({
    values,
    resetValues,
  });

  return (
    <>
      <form onSubmit={submitContact}>
        <fieldset>
          <label htmlFor="name-input">Naam</label>
          <input
            type="text"
            name="naam"
            required="required"
            id="name-input"
            placeholder="Uw naam"
            value={values.naam}
            onChange={updateValues}
          />
          <label htmlFor="email-input">Email</label>
          <input
            type="email"
            name="email"
            required="required"
            id="email-input"
            placeholder="me@mail.com"
            value={values.email}
            onChange={updateValues}
          />
          <label htmlFor="textarea-input">Bericht</label>
          <textarea
            type="text"
            name="bericht"
            required="required"
            id="textarea-input"
            placeholder="Uw bericht"
            value={values.bericht}
            onChange={updateValues}
          />
          <input
            type="siroop"
            name="siroop"
            id="siroop"
            className="siroop"
            value={values.siroop}
            onChange={updateValues}
          />
        </fieldset>
        <fieldset disabled={loading} className="submit-button">
          <ButtonStyles type="submit">
            {loading ? 'Verzenden...' : 'Verzend'}
          </ButtonStyles>
        </fieldset>
      </form>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default Form;
